<template>
  <div>
    <button v-if="$store.state.permissions.includes('role-store')" type="submit" class="btn btn-info float-right" @click="showModal" style="margin-right: 15px">Add Role</button>
    <b-row class="ml-1 "><h3>All Admin Roles</h3></b-row>
    <div class="mt-2 mr-5 d-flex">
      <div class="col-lg-2 margin">
        <label for="" class="mt-0 font-weight-bold font-12">Records Per Page </label>
        <b-form-select class="hand" :options="pageOptions" v-model="perPage"/>
      </div>
    </div>
    <b-row class="mt-3">
      <b-col>
        <b-table small responsive show-empty empty-text="No users found for this company" striped bordered hover fixed
          :items="role_item()"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="search"
          @filtered="onFiltered"
          class="break"
        >
          <template  v-slot:cell(admin_user_roles)="cell">
            <span :key="index" class="list" v-for="(item, index) in cell.item.admin_user_roles" >
                <b-badge pill variant="info"> {{ item.name }}</b-badge>
            </span>
          </template>
          <template v-slot:cell(action)="cell">
          <!-- We use click.stop here to prevent a 'row-clicked' event from also happening -->
<!--            <span class="impersonate" v-if="cell.item.admin_user_roles.length > 0">-->
<!--              <i-->
<!--                @click="deleteRole(cell.item)"-->
<!--                aria-hidden="true"-->
<!--                class="fa fa-trash ml-1 hand"-->
<!--                title="Delete this role"-->
<!--                v-b-tooltip.hover-->
<!--              />-->
<!--            </span>-->
            <span v-if="cell.item.admin_user_roles.length > 0">
              <i v-if="$store.state.permissions.includes('role-update')"
                 @click="editModel(cell.item)"
                 class="fa fa-pencil ml-1 hand" title="Edit Permissions" v-b-tooltip.hover/>
            </span>
          </template>
        </b-table>
        <b-row>
          <b-col md="12" class="my-1">
            <b-pagination
              align="right"
              aria-controls="companies_table"
              :total-rows="totalRows"
              :per-page="perPage"
              v-model="currentPage"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <add-role-modal :modelopen="open" :userpermissions="permission" />
    <edit-role-modal :modelopen="open" :userpermissions="permission" :roleName="role_name" :selectPermission="editper" :roleList="role_hash_id" ref="editdata"/>
  </div>
</template>

<script>
import { mapState } from "vuex";
import select2 from "../components/assets/select2";
import moment from "moment";
import $ from "jquery";
import addRoleModal from "../components/AdminRole/addRoleModal";
import editRoleModal from "../components/AdminRole/editRoleModal";

export default {
  name: "adminRoles",
  components: {
    select2,
    addRoleModal,
    editRoleModal,
  },
  props: ["modelopen", "userpermissions" ,'roleName','selectPermission','roleList'],
  data() {
    return {
      open: false,
      permission: [],
      rolelists: [],
      editper:[],
      role_name:'',
      role_hash_id:'',
      fields: [
        {
        key:'name',
          label: "Name",
          thStyle: {
            width: "130px"
          }
        },
       {
         key:'admin_user_roles',
          label: "Role",
          thStyle: {
            width: "150px"
          }
        },
        {
          key:'action',
          label: "Action",
          thStyle: {
            width: "70px"
          }
        }
      ],
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      search: null,
      totalRows: 0
    };
  },
  computed: {
    ...mapState(["admin_roles_list"]),
    items() {},

    isBusy() {
      return this.$store.state.isBusy;
    }
  },
  mounted() {
    this.rolelist();
    this.getallper();
  },
  methods: {
    getallper() {
      this.$store
        .dispatch("getRolesPermission")
        .then(response => {
          this.permission = response.data.permissions;
        })
        .catch(error => {
          console.log(error);
        });
    },
    detailModel(user) {
      this.$root.$emit("bv::show::modal", "detailsModel");
      this.$root.$emit("selected_user", user);
    },
    showModal() {
      this.open = true;
      let element = document.getElementById("exampleModal1");
      $(element).modal("show");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    role_item()
    {
      return this.rolelists;
    },
    rolelist() {
      this.$store.commit("toggle_loader", true);
      this.$store.dispatch("getAdminRoleList")
        .then(response => {
          this.$store.commit("toggle_loader", false);
          this.rolelists = response.data.users_roles;
        })
        .catch(error => {
          console.log(error);
        });
    },
    addnewrole() {
      this.$router.push("/add-role");
    },

    deleteRole(role) {
      if (
        confirm(
          "Are you really sure to delete this role? think twice before you proceed!"
        )
      ) {
        let data = {
          admin_id: role.hash_id,
          role_id: role.admin_user_roles.map(function(del_role) {
            return del_role.hash_id;
          }),
          flag: true
        };
        this.$store
          .dispatch("deleteAdminRole", data)
          .then(response => {
            this.$store.commit("toggle_loader", false);
            this.rolelist();
            alert(response.data.errors.role_id);
          })
          .catch(() => {
            this.$store.commit("toggle_loader", false);
          });
      }
    },
    editModel(role) {
      let data = {
        role_id: role.admin_user_roles[0].hash_id,
      }
      this.role_name = role.admin_user_roles[0].name
      this.role_hash_id = role.admin_user_roles[0].hash_id
      this.$store.commit("toggle_loader", true);
      this.$store.dispatch("editPermission",data)
       .then(response => {
         this.$store.commit("toggle_loader", false);
         this.permission = response.data.permissions;
         this.editper = response.data.role_permission;
         let perm = [];
         this.editper.forEach(function(item){
           perm.push(item.id)
         });
         this.editper = perm;
         this.$nextTick(() => {
           this.$refs.editdata.isLoop();
         });
       }).catch(error => {
                console.log(error);
      });
      this.open = true;
      let element = document.getElementById("exampleModal2");
      $(element).modal("show");
    }
  }
};
</script>

<style scoped>
.daterange-dis {
  font-size: smaller;
}

.archive {
  margin-left: 10px;
}
.unarchive {
  margin-left: 10px;
}
.impersonate {
  margin-left: 10px;
}

.list {
  list-style-type: none;
  font-size: 16px;
  /*padding: 4px;*/
  /*box-shadow: 0 2px 4px 0 rgba(167, 161, 161, 0.5);*/
}


.image {
  max-height: 200px;
  min-height: 200px;
  max-width: 200px;
  min-width: 200px;
  border-radius: 3px;
}

.margin {
  margin-left: -15px;
}

.flex5 {
  margin-left: -20px;
}
.fa-style {
  font-size: 16px;
  padding-top: 3px;
}
.badge-pill {
  cursor: pointer !important;
}
</style>
