<template>
  <div id="example-modal">
    <!-- Modal -->
    <div class="modal fade" ref="modal" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Role</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div></div>
          <div></div>
          <div class="modal-body">
            <form ref="role_form" @submit="submit">
              <b-form-group class="col-md-12 col-lg-12 col-xl-12 col-sm-12 b-form-group form-group">
                <label>Name</label>
                <b-form-input required  name="name" v-model="name" autocomplete="username" placeholder="Name">
                </b-form-input>
                <div class="message">
                  {{ validation.firstError("name") }}
                </div>
              </b-form-group>
              <div>
                <b-row class="ml-1 ">
                  <p class="p-text">Permissions</p>
                </b-row>
              </div>
              <!--                            <b-form-group id="input-group-4">-->
              <!--                                <b-form-checkbox-group v-model="checked" id="checkboxes-4">-->
              <!--                                    <b-form-checkbox value="me">Select All</b-form-checkbox>-->
              <!--                                </b-form-checkbox-group>-->
              <!--                            </b-form-group>-->

              <div>
                <b-card no-body>
                  <b-tabs pills card vertical>
                    <b-tab :key="index" class="list" v-for="(item, index) in this.permissionsubmit()" :title="index" active>
                      <b-card-text>
                        <span v-for="user in item">
                          <ul>
                            <li>
                              <input type="checkbox" :id="user.id" name="permission_ids" :value="user.id" v-model="permission_ids" @click="oncheck(user.id)"/>
                              {{ user.name }}
                            </li>
                          </ul>
                        </span>
                      </b-card-text>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </div>
              <div class="modal-footer">
                <VueLoadingButton aria-label="Close" class="btn btn-secondary" data-dismiss="modal" :disabled="state.isSending">Close</VueLoadingButton>
                <div>
                  <div class="example">
                    <VueLoadingButton aria-label="Post message" class="btn btn-primary" @click.native="submit" :loading="isLoading" :styled="!isStyled">Submit</VueLoadingButton>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import $ from "jquery";
import VueLoadingButton from "vue-loading-button";
import SimpleVueValidation from "simple-vue-validator";
const Validator = SimpleVueValidation.Validator;
Vue.use(SimpleVueValidation);
export default {
  components: {
    name: "addRoleModal",
    VueLoadingButton
  },
  props: ["modelopen", "userpermissions"],

  data() {
    return {
      opened: false,
      listOfObjects: [],
      permission_ids: [],
      getPermission: [],
      name: "",
      isLoading: false,
      isStyled: false,
      users_erros: {
        invalidEmail: false,
        invalidphone_number: false
      },
      state: {
        isSending: false
      }
    };
  },
  validators: {
    name: function(value) {
      return Validator.value(value).required("Name is required");
    }
  },
  mounted() {
    this.opened = this.modelopen;
    if (this.opened == true) {
      let element = document.getElementById("exampleModal");
      $(element).modal("show");
    }
    this.permissionsubmit();
  },
  methods: {
    oncheck(val) {
      this.permission_ids.push(val);
    },
    permissionsubmit() {
      return (this.getPermission = this.userpermissions);
    },
    submit() {
      var self = this;
      this.$validate().then(function(pass) {
        if (pass === true) {
          self.handleSubmit();
        }
      });
    },
    handleSubmit(evt) {
      this.state.isSending = true;
      this.isLoading = true;
      let element = document.getElementById("exampleModal");
      $(element).modal("show");
      let data = {
        name: this.name,
        permission_ids: this.permission_ids
      };
      this.$store.dispatch("addRole", data)
        .then(response => {
          this.isLoading = false;
          this.state.isSending = false;
          this.name = "";
          this.permission_ids = "";
          if (response.data.error === false) {
            let element1 = document.getElementById("exampleModal1");
            $(element1).modal("hide");
            this.$notify("Success!", response.data.error_msg, "success");
            this.$store.commit(
              response.data
            );
          }
        })
        .catch(error => {
          this.isLoading = false;
          this.state.isSending = false;
          if (error.response) {
            if (error.response.data.errors.email) {
              this.$notify("Error!", error.response.data.errors.email);
            }
            if (error.response.data.errors.phone_number) {
              this.$notify("Error!", error.response.data.errors.phone_number);
            }
            if (error.response.data.errors.name) {
              this.$notify("Error!", error.response.data.errors.name);
            }
          }

          this.$store.commit("custom/toggle_loader", false);
        });
      this.$refs.exampleModal.hide();
    }
  }
};
</script>

<style scoped>
.message {
  color: #ff0000;
  font-size: 14px;
  text-align: left;
  display: block;
  padding: 5px 0 0;
}
.p-text{
  margin-left: 2%;
}
</style>
<style>
li {
  display: block !important;
}
.form-control
{
  width: 50% !important;
}
.card
{
  width: 90% !important;
  margin-left: 2% !important;
}
.modal-footer
{
  border-top: 1px solid #dee2e600 !important;
  margin-top: 2% !important;
}
.default-styles {
  padding: 5px 16.5px 5px 16px !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}
</style>
